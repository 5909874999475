import { useMemo } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import camelCase from "lodash/camelCase";
import startCase from "lodash/startCase";
import { get, size } from "lodash";

import removeLeadingSlash from "@utils/removeLeadingSlash";
import removeTrailingSlash from "@utils/removeTrailingSlash";
import { RoadmapItemProps } from "@components/Roadmap/partials/RoadmapItem/types";

import { PlanFormSection, PlanFormStep } from "./types";
import { getLoopStepsPath, getNextStep } from "./utils";
import { ITERATION_QUERY_KEY } from "./consts";

const useStepsPath = (
  steps: PlanFormStep[],
  values: Record<string, unknown>
): string[] =>
  useMemo(() => {
    let currentStepParent: PlanFormStep | undefined = steps[0];
    let currentStepChild: PlanFormStep | undefined =
      currentStepParent?.children?.[0];

    if (!currentStepParent || currentStepParent.path === undefined) {
      return [];
    }

    const firstStepPath =
      removeTrailingSlash(
        currentStepChild
          ? `${currentStepParent.path}/${currentStepChild.path}`
          : currentStepParent.path
      ) || "";

    const stepsKeys: string[] = [firstStepPath];
    let counter = 0;

    while (currentStepParent && counter < 1000) {
      counter += 1;

      const nextStep = getNextStep(
        { parent: currentStepParent, child: currentStepChild },
        steps,
        values
      );

      currentStepParent = nextStep?.parent;
      currentStepChild = nextStep?.child;

      if (nextStep) {
        const { enabled: isLoop, iterationCountSource = "" } =
          currentStepChild?.loop || {};

        if (isLoop && currentStepChild?.children?.length) {
          const iterationCount = size(
            get(values, iterationCountSource, []) as
              | Array<unknown>
              | Record<string, unknown>
          );

          const loopRoutes = currentStepChild?.children;

          const path = removeLeadingSlash(
            removeTrailingSlash(
              `${currentStepParent?.path}/${
                currentStepChild.path || ""
              }`
            )
          );

          Array.from({ length: iterationCount }).some(
            // eslint-disable-next-line no-loop-func
            (_, iteration) => {
              const loopStepsPath = getLoopStepsPath(
                loopRoutes,
                values,
                iteration
              );

              return loopStepsPath.some((loopStep) => {
                const loopStepPath = `${path}/${loopStep.path}`;

                if (loopStep.break) {
                  const breakStepPath = removeLeadingSlash(
                    removeTrailingSlash(
                      `${currentStepParent?.path}/${
                        loopStep.path || ""
                      }`
                    )
                  );

                  currentStepChild =
                    currentStepParent?.children?.find(
                      (i) => i.path === loopStep.path
                    );

                  if (breakStepPath) {
                    stepsKeys.push(breakStepPath);
                  }

                  return true;
                }

                const loopStepPathWithIteration = `${removeTrailingSlash(
                  loopStepPath
                )}?${ITERATION_QUERY_KEY}=${iteration + 1}`;

                if (
                  loopStepPathWithIteration &&
                  !stepsKeys.includes(loopStepPathWithIteration)
                ) {
                  stepsKeys.push(loopStepPathWithIteration);
                }

                return false;
              });
            }
          );
        } else {
          const path = removeLeadingSlash(
            removeTrailingSlash(
              nextStep?.child?.path
                ? `${nextStep?.parent?.path}/${nextStep?.child?.path}`
                : nextStep?.parent?.path
            )
          );

          // If we get to the same step or we already have step in steps
          // list we have to break to avoid infinite loop
          if (stepsKeys.includes(path || "")) {
            break;
          }

          if (path) {
            stepsKeys.push(path);
          }
        }
      }
    }

    return stepsKeys;
  }, [steps, values]);

const useNextStepKey = (
  activeStepKey: string | undefined,
  stepsPath: string[]
): string | undefined =>
  useMemo(() => {
    if (activeStepKey === undefined) {
      return undefined;
    }

    const currentStepIndex = stepsPath.indexOf(activeStepKey);

    if (currentStepIndex < 0) {
      return undefined;
    }

    return stepsPath[currentStepIndex + 1];
  }, [activeStepKey, stepsPath]);

const usePrevStepKey = (
  activeStepKey: string | undefined,
  stepsPath: string[]
): string | undefined =>
  useMemo(() => {
    if (activeStepKey === undefined) {
      return undefined;
    }

    const currentStepIndex = stepsPath.indexOf(activeStepKey);

    if (currentStepIndex < 0) {
      return undefined;
    }

    return stepsPath[currentStepIndex - 1];
  }, [activeStepKey, stepsPath]);

const useActiveStepKey = (
  parentPath: string,
  steps: string[]
): string | undefined => {
  const { pathname, search } = useLocation();
  const currentStepPath = removeLeadingSlash(
    removeTrailingSlash(pathname.replace(`${parentPath}`, ""))
  );

  return useMemo(
    () =>
      steps.find((step) => step === `${currentStepPath}${search}`),
    [currentStepPath, search, steps]
  );
};

const useSections = (
  steps: PlanFormSection[],
  activeStepKey?: string
): RoadmapItemProps[] | undefined => {
  let state: RoadmapItemProps["state"] = "prev";

  const filteredSections = steps.filter(
    ({ path, hideInMenu }) =>
      path !== "*" && !!path?.length && !hideInMenu
  );

  const [activeSection] = activeStepKey?.split("_") || [
    filteredSections[0].path
  ];

  const sections = filteredSections.map(
    ({ path, icon, isOptional }) => {
      const label = path?.replace("-", " ");

      if (state === "current") {
        state = "next";
      }

      if (state !== "next" && path === activeSection) {
        state = "current";
      }

      return {
        label: startCase(camelCase(label)) ?? "",
        icon,
        to: path ?? "",
        isOptional,
        state
      };
    }
  );

  return sections;
};

const useFormProgress = (
  parentPath: string,
  steps: PlanFormStep[]
): number | undefined => {
  const { pathname } = useLocation();
  const currentStepPath =
    removeLeadingSlash(pathname.replace(`${parentPath}`, "")) || "";
  const currentStepSection = currentStepPath.split("/");
  const currentPath =
    currentStepSection.length > 1 ? `${currentStepSection[1]}` : "";

  const activeSection = useMemo(
    () =>
      steps.find(
        ({ path: sectionPath }) =>
          sectionPath === currentStepSection[0]
      )?.children || [],
    [currentStepSection, steps]
  );

  const stepsLength = activeSection.filter(
    (step) => step.path !== "*"
  ).length;

  const activeIndex = useMemo(
    () =>
      activeSection?.findIndex((step) => step.path === currentPath),
    [currentPath, activeSection]
  );

  const progress =
    activeIndex && stepsLength
      ? Math.round(((activeIndex + 1) / stepsLength) * 100)
      : 0;

  return progress;
};

const useAccessiblePaths = (
  stepsPath: string[],
  steps: PlanFormSection[],
  userLastPath?: string
): { [key: string]: boolean } =>
  useMemo(() => {
    const lastPathIndex = stepsPath.findIndex(
      (path) => path === userLastPath
    );

    const completedSteps = stepsPath.slice(0, lastPathIndex + 1);

    let prevSection: string | null = null;

    const completedSections = completedSteps
      .filter(Boolean)
      .reduce<string[]>((prev, current) => {
        const [sectionName] = current.split("/");

        if (prevSection && sectionName !== prevSection) {
          return [...prev, prevSection];
        }

        prevSection = sectionName;

        return prev;
      }, []);

    const sectionsStatus = steps.reduce<{
      [name: string]: boolean;
    }>((prev, { path, dependency = [] }) => {
      if (!path) {
        return prev;
      }

      if (
        !stepsPath.some((currentStepPath) =>
          currentStepPath.startsWith(path)
        )
      ) {
        return prev;
      }

      return {
        ...prev,
        [path]: dependency.every((dep) =>
          completedSections.includes(dep)
        )
      };
    }, {});

    return sectionsStatus;
  }, [steps, stepsPath, userLastPath]);

const useIterationIndex = (): number | undefined => {
  const [searchParams] = useSearchParams();

  const iteration = parseInt(
    searchParams.get(ITERATION_QUERY_KEY) || "",
    10
  );

  return Number.isNaN(iteration) ? undefined : iteration - 1;
};

export {
  useStepsPath,
  useNextStepKey,
  usePrevStepKey,
  useActiveStepKey,
  useFormProgress,
  useSections,
  useAccessiblePaths,
  useIterationIndex
};
