import { Button, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { FormattedMessage } from "react-intl";

import { LAST_WILL_POWER_OF_ATTORNEY_ROUTES } from "@pages/LastWill/sections/PowerOfAttorney/consts";
import { useMemberPlansState } from "@providers/memberPlansState/context";
import { FileType } from "@api/__generated__/globalTypes";
import { LAST_WILL_SECTION_ROUTES } from "@pages/LastWill/consts";
import { ROUTES } from "@setup/consts/routes";
import Image from "@components/Image";
import POAImage from "@assets/images/docIcons/DocumentIconPOA.png";
import { File } from "@api/files/__generated__/File";
import { User } from "@api/me/__generated__/User";
import { POWER_OF_ATTORNEY_ROUTES as POA_ROUTES } from "@pages/PowerOfAttorney/consts";
import { POWER_OF_ATTORNEY_ROUTES } from "@pages/PowerOfAttorney/steps/main/consts";

import { useSlideActions } from "../hooks";
import { getLinkFullUrl } from "../utils";

const PowerOfAttorneySlide = () => {
  const { trust, lastWill } = useMemberPlansState();

  const data = useMemo(() => {
    let planFile;
    let editLink;
    let activePlan;
    let primaryDrafter: User;

    if (trust) {
      primaryDrafter = trust?.plan?.primaryDrafter;

      planFile = trust?.plan?.files?.find(
        (f: File) =>
          f.type === FileType.POA_DOCUMENT &&
          f.ownerId === primaryDrafter.id
      );

      editLink = getLinkFullUrl([
        ROUTES.PowerOfAttorney,
        POA_ROUTES.Home,
        POWER_OF_ATTORNEY_ROUTES.POADedicatedOverviewStep
      ]);

      activePlan = trust;
    } else {
      primaryDrafter = lastWill?.plan?.primaryDrafter;
      planFile = lastWill?.plan?.files?.find(
        (f: File) =>
          f.type === FileType.POA_DOCUMENT &&
          f.ownerId === primaryDrafter.id
      );

      editLink = getLinkFullUrl([
        ROUTES.LastWill,
        LAST_WILL_SECTION_ROUTES.PowerOfAttorney,
        LAST_WILL_POWER_OF_ATTORNEY_ROUTES.Preview
      ]);

      activePlan = lastWill;
    }

    return {
      primaryDrafter,
      planFile,
      editLink,
      activePlan
    };
  }, [trust, lastWill]);

  const actions = useSlideActions({
    planFile: data.planFile,
    editLink: data.editLink,
    memberPlan: data.activePlan
  });

  const navigate = useNavigate();

  const handleGoToEdit = useCallback(() => {
    if (data.editLink) {
      navigate(data.editLink);
    }
  }, [data.editLink, navigate]);

  const renderTop = useCallback(
    () =>
      (trust || lastWill) && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center"
          }}
        >
          <Typography align="center" variant="h5">
            <FormattedMessage defaultMessage="Power of Attorney" />
          </Typography>
          <Typography align="center" variant="h5">
            <FormattedMessage
              defaultMessage="of {fullName}"
              values={{
                fullName: data.primaryDrafter.fullName ?? ""
              }}
            />{" "}
          </Typography>
          <Box sx={{ mt: "1rem" }}>
            <Image
              height="64px"
              width="64px"
              src={POAImage}
              alt="Power of Attorney icon"
            />
          </Box>
          <Typography mt="1.2rem" align="center" fontSize="1rem">
            <FormattedMessage
              defaultMessage="Authorize a trusted individual to make certain decisions and
            transactions on your behalf."
            />
          </Typography>
        </Box>
      ),
    [trust, lastWill, data.primaryDrafter.fullName]
  );

  if (
    !trust?.primaryDrafterLastWill?.powerOfAttorney &&
    !lastWill?.primaryDrafterLastWill?.powerOfAttorney
  ) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column"
        }}
      >
        {renderTop()}
        <Typography mt="1.5rem" align="center" fontSize="15px">
          <FormattedMessage defaultMessage="You haven’t created a Power of Attorney yet." />
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center"
          }}
        >
          <Button
            onClick={handleGoToEdit}
            variant="contained"
            sx={{
              flex: "1",
              borderWidth: "2px",
              fontWeight: "500",
              fontSize: "14px",
              marginTop: "1.1rem"
            }}
          >
            <FormattedMessage defaultMessage="CREATE POWER OF ATTORNEY" />
          </Button>
        </Box>
      </Box>
    );
  }

  return (
    <>
      {renderTop()}
      <Grid
        container
        item
        xs={12}
        mt="1.5rem"
        justifyContent="space-around"
      >
        {actions.map(({ icon, label }) => (
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "0.5rem",
              cursor: "pointer"
            }}
            key={label}
          >
            {icon}
            <Typography fontSize="0.875rem">{label}</Typography>
          </Box>
        ))}
      </Grid>
    </>
  );
};

export default PowerOfAttorneySlide;
