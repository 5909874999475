import { useMemo } from "react";

import { PlanOption } from "@api/__generated__/globalTypes";
import { useMemberPlansState } from "@providers/memberPlansState/context";
import { AutocompleteBaseOption } from "@components/FormFields/FormAutocomplete/types";

export const usePolicyHolderOptions =
  (): AutocompleteBaseOption[] => {
    const { trust, lastWill } = useMemberPlansState();
    const primaryDrafterFullName = trust
      ? trust?.plan?.primaryDrafter?.fullName
      : lastWill?.plan?.primaryDrafter?.fullName;
    const partnerFullName = trust
      ? trust?.plan?.partner?.fullName
      : lastWill?.plan?.partner?.fullName;

    const planOption = trust
      ? trust?.plan?.planOption === PlanOption.COUPLE
      : lastWill?.plan?.planOption === PlanOption.COUPLE;

    return useMemo<AutocompleteBaseOption[]>(
      () =>
        [
          {
            label: primaryDrafterFullName ?? "",
            value: primaryDrafterFullName ?? "",
            key: primaryDrafterFullName ?? ""
          },
          {
            ...(planOption
              ? {
                  label: partnerFullName ?? "",
                  value: partnerFullName ?? "",
                  key: partnerFullName ?? ""
                }
              : {})
          }
        ].filter((item): item is AutocompleteBaseOption =>
          Boolean(item.label)
        ),
      [partnerFullName, planOption, primaryDrafterFullName]
    );
  };
