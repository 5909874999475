import { Box } from "@mui/material";
import { useIntl } from "react-intl";

import Modal from "@components/Modal";
import AddAssetButton from "@pages/EstatePlanView/Partials/components/AddAssetButton";

import AssetForm from "../AssetForm";

import { useAddQuestionnaireAssetModal } from "./hooks";
import { AddAssetModalProps } from "./types";

const AddAssetsModal: React.FC<AddAssetModalProps> = ({
  userIndex,
  showIsFunded = false
}) => {
  const intl = useIntl();
  const { isOpen, toggleOn, toggleOff, onSubmit } =
    useAddQuestionnaireAssetModal(userIndex);

  return (
    <Box
      sx={{
        marginTop: "1.5rem"
      }}
    >
      <AddAssetButton
        onClick={toggleOn}
        variant="outlined"
        color="inherit"
      />
      <Modal
        fullWidth
        open={isOpen}
        onClose={toggleOff}
        title={intl.formatMessage({
          defaultMessage: "Add Asset"
        })}
        content={
          <AssetForm {...{ onSubmit, userIndex, showIsFunded }} />
        }
      />
    </Box>
  );
};

export default AddAssetsModal;
