import { useMemo } from "react";
import { useIntl } from "react-intl";

import {
  DocumentsListGroupItem,
  DocumentsListItem
} from "@components/Documentslist/types";
import { useGetDraftDocumentsQuery } from "@api/planDraftDocuments/hooks";
import { usePlanForm } from "@providers/planForm/context";
import { getUserName } from "@utils/getUserName";
import { useCheckout } from "@hooks/useCheckout";
import { PlanType } from "@api/__generated__/globalTypes";

import {
  PlanFormUsersValues,
  UseDocumentsPreviewReturnType
} from "./types";
import {
  getUserDocumentsByType,
  mapPlanDocumentsToUserDocuments
} from "./utils";
import {
  HEALTHCARE_DOCUMENTS,
  PET_PROTECTION_DOCUMENTS,
  POA_DOCUMENTS,
  WILL_DOCUMENTS,
  TRUST_DOCUMENTS
} from "./consts";

const useDocuments = (
  primaryDrafterDocuments: DocumentsListGroupItem[],
  partnerDocuments: DocumentsListGroupItem[] = []
): DocumentsListItem[] => {
  const intl = useIntl();

  return useMemo(() => {
    const documents = [
      ...primaryDrafterDocuments,
      ...(partnerDocuments || [])
    ];

    return [
      ...getUserDocumentsByType(
        documents,
        WILL_DOCUMENTS,
        intl.formatMessage({
          defaultMessage: "Last Will Documents"
        })
      ),
      ...getUserDocumentsByType(
        documents,
        TRUST_DOCUMENTS,
        intl.formatMessage({
          defaultMessage: "Trust Documents"
        })
      ),
      ...getUserDocumentsByType(
        documents,
        PET_PROTECTION_DOCUMENTS,
        intl.formatMessage({
          defaultMessage: "Pet Estate Plan Documents"
        })
      ),
      ...getUserDocumentsByType(
        documents,
        HEALTHCARE_DOCUMENTS,
        intl.formatMessage({
          defaultMessage: "Healthcare Documents"
        })
      ),
      ...getUserDocumentsByType(
        documents,
        POA_DOCUMENTS,
        intl.formatMessage({
          defaultMessage: "Power of Attorney Documents"
        })
      )
    ];
  }, [intl, partnerDocuments, primaryDrafterDocuments]);
};

export const useDocumentsPreview =
  (): UseDocumentsPreviewReturnType => {
    const intl = useIntl();
    const {
      token,
      type: planType,
      values: formValues
    } = usePlanForm<PlanFormUsersValues>();

    const { loading, data } = useGetDraftDocumentsQuery({
      variables: { token: token || "" },
      skip: !token
    });

    const successReturnPath = useMemo(() => {
      const baseSuccessPath = "?paymentSuccess=true";

      if (planType === PlanType.PET_PROTECTION) {
        return `${baseSuccessPath}&planType=petProtection`;
      }

      return baseSuccessPath;
    }, [planType]);

    const { loading: paymentLoading, getCheckoutUrl } = useCheckout(
      token,
      successReturnPath
    );

    const handleGoToCheckout = async (): Promise<void> => {
      const url = await getCheckoutUrl();

      if (url) {
        window.location.href = url;
      }
    };

    const user = getUserName(formValues?.users?.[0]);
    const partnerName = getUserName(formValues?.users?.[1]);
    const userDocuments = mapPlanDocumentsToUserDocuments(
      intl,
      planType,
      data?.planDraftDocuments?.primaryDrafterDocuments,
      user
    );

    const partnerDocuments = mapPlanDocumentsToUserDocuments(
      intl,
      planType,
      data?.planDraftDocuments?.partnerDocuments,
      partnerName
    );

    const items = useDocuments(userDocuments, partnerDocuments);

    return {
      items,
      loading,
      handleGoToCheckout,
      paymentLoading
    };
  };
