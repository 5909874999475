const GRAPHQL_API_URL = ((): string => {
  if (!process.env.REACT_APP_GRAPHQL_API_URL) {
    throw new Error(
      "Missing environment variable - REACT_APP_GRAPHQL_API_URL"
    );
  }

  return process.env.REACT_APP_GRAPHQL_API_URL;
})();

const GOOGLE_MAPS_API_KEY = ((): string => {
  if (!process.env.REACT_APP_GOOGLE_MAPS_API_KEY) {
    throw new Error(
      "Missing environment variable - REACT_APP_GOOGLE_MAPS_API_KEY"
    );
  }

  return process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
})();

const COGNITO_CLIENT_ID = ((): string => {
  if (!process.env.REACT_APP_COGNITO_CLIENT_ID) {
    throw new Error(
      "Missing environment variable - REACT_APP_COGNITO_CLIENT_ID"
    );
  }

  return process.env.REACT_APP_COGNITO_CLIENT_ID;
})();

const USER_POOL_ID = ((): string => {
  if (!process.env.REACT_APP_COGNITO_USER_POOL_ID) {
    throw new Error(
      "Missing environment variable - REACT_APP_COGNITO_USER_POOL_ID"
    );
  }

  return process.env.REACT_APP_COGNITO_USER_POOL_ID;
})();

const COGNITO_REGION = ((): string => {
  if (!process.env.REACT_APP_COGNITO_REGION) {
    throw new Error(
      "Missing environment variable - REACT_APP_COGNITO_REGION"
    );
  }

  return process.env.REACT_APP_COGNITO_REGION;
})();

const COGNITO_REDIRECT_SIGN_IN = ((): string => {
  if (!process.env.REACT_APP_COGNITO_REDIRECT_SIGN_IN) {
    throw new Error(
      "Missing environment variable - REACT_APP_COGNITO_REDIRECT_SIGN_IN"
    );
  }

  return process.env.REACT_APP_COGNITO_REDIRECT_SIGN_IN;
})();

const COGNITO_REDIRECT_SIGN_OUT = ((): string => {
  if (!process.env.REACT_APP_COGNITO_REDIRECT_SIGN_OUT) {
    throw new Error(
      "Missing environment variable - REACT_APP_COGNITO_REDIRECT_SIGN_OUT"
    );
  }

  return process.env.REACT_APP_COGNITO_REDIRECT_SIGN_OUT;
})();

const COGNITO_DOMAIN = ((): string => {
  if (!process.env.REACT_APP_COGNITO_DOMAIN) {
    throw new Error(
      "Missing environment variable - REACT_APP_COGNITO_DOMAIN"
    );
  }

  return process.env.REACT_APP_COGNITO_DOMAIN;
})();

const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;

const MEMBERS_PORTAL_URL = 'https://members.justincaseestates.com';
const INVITATION_PATH = '/invitation?financialAdvisorToken=';

export {
  GRAPHQL_API_URL,
  GOOGLE_MAPS_API_KEY,
  COGNITO_CLIENT_ID,
  USER_POOL_ID,
  COGNITO_REGION,
  SENTRY_DSN,
  COGNITO_DOMAIN,
  COGNITO_REDIRECT_SIGN_IN,
  COGNITO_REDIRECT_SIGN_OUT,
  MEMBERS_PORTAL_URL,
  INVITATION_PATH
};
