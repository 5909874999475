import { useMemo, useState } from "react";
import { useIntl } from "react-intl";
import * as yup from "yup";
import { SchemaOf } from "yup";
import { UseFormSetError } from "react-hook-form";
import { Auth } from "aws-amplify";
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";
import { useNavigate } from "react-router-dom";

import imageGoogle from "@assets/images/google.png";
// import imageAmazon from "@assets/images/amazon.png";
// import imageFacebook from "@assets/images/facebook.png";
import { ButtonsListItemProps } from "@components/ButtonsList/types";
import useCognitoAuth from "@hooks/useCognitoAuth";
import useUserContext from "@providers/user/hooks";
import { providerColors } from "@setup/theme";
import { ROUTES } from "@setup/consts/routes";

import {
  SignInFormDataProps,
  UseSignInFormReturnType
} from "./types";

export const useButtonsListItem = (): ButtonsListItemProps[] => {
  const intl = useIntl();

  return useMemo(
    () => [
      {
        key: "1",
        label: intl.formatMessage({
          defaultMessage: "Sign in with google"
        }),
        backgroundColor: providerColors.GOOGLE,
        onClick: () =>
          Auth.federatedSignIn({
            provider: CognitoHostedUIIdentityProvider.Google
          }),
        imgSrc: imageGoogle
      }
      /*
      {
        key: "2",
        label: intl.formatMessage({
          defaultMessage: "Sign in with facebook"
        }),
        backgroundColor: providerColors.FACEBOOK,
        onClick: () =>
          Auth.federatedSignIn({
            provider: CognitoHostedUIIdentityProvider.Facebook
          }),
        imgSrc: imageFacebook
      },
      {
        key: "3",
        label: intl.formatMessage({
          defaultMessage: "Sign in with amazon"
        }),
        backgroundColor: providerColors.AMAZON,
        onClick: () =>
          Auth.federatedSignIn({
            provider: CognitoHostedUIIdentityProvider.Amazon
          }),
        imgSrc: imageAmazon
      }
      */
    ],
    [intl]
  );
};

export const useSignInFormValidationSchema =
  (): SchemaOf<SignInFormDataProps> =>
    useMemo(
      () =>
        yup
          .object({
            email: yup.string().email().required(),
            password: yup.string().required()
          })
          .required(),
      []
    );

export const useSignInForm = (
  setError: UseFormSetError<SignInFormDataProps>
): UseSignInFormReturnType => {
  const [errorMessage, setErrorMessage] = useState<string>();
  const { signIn } = useCognitoAuth();
  const { setCognitoUser } = useUserContext();
  const intl = useIntl();
  const navigate = useNavigate();

  const handleLogin = async ({
    email,
    password
  }: SignInFormDataProps): Promise<void> => {
    try {
      const user = await signIn(email, password);

      setCognitoUser(user);
      navigate(ROUTES.Root);
    } catch (error) {
      const formError = error as Error;

      if (formError.name === "UserNotConfirmedException") {
        setErrorMessage(formError.message);
        setError("email", { type: "custom", message: "" });
        setError("password", { type: "custom", message: "" });
      }

      if (formError.name === "NotAuthorizedException") {
        setErrorMessage(
          intl.formatMessage({
            defaultMessage:
              "Invalid email address or password. Please try again, or register now for a new account"
          })
        );
        setError("email", { type: "custom", message: "" });
        setError("password", { type: "custom", message: "" });
      }

      throw error;
    }
  };

  return {
    errorMessage,
    handleLogin
  };
};
