import { FormattedMessage, useIntl } from "react-intl";
import { Stack, FormControl, Typography, Box } from "@mui/material";

import BasicFormAutocomplete from "@components/FormFields/FormAutocomplete/BasicAutocomplete";
import { useLifeInsuranceCompanyOptions } from "@hooks/useLifeInsuranceCompanyOptions";
import BasicCurrencyFormInput from "@components/FormFields/FormInput/BasicCurrencyFormInput";

import { usePolicyHolderOptions } from "../../hooks/usePolicyHolderOptions";

import SpecificGift from "./SpecificGift";
import FundedToTrust from "./FundedToTrust";

const LifeInsurancePolicy = ({ showIsFunded = false }) => {
  const policyHolderOptions = usePolicyHolderOptions();
  const lifeInsuranceCompanies = useLifeInsuranceCompanyOptions();
  const { formatMessage } = useIntl();

  return (
    <Stack spacing={2}>
      <FormControl variant="standard" sx={{ display: "flex" }}>
        <Stack spacing={2}>
          <BasicFormAutocomplete
            name="institution"
            options={lifeInsuranceCompanies}
            textFieldProps={{
              label: formatMessage({
                defaultMessage: "Life Insurance Company Name"
              })
            }}
            freeSolo
          />
          <BasicFormAutocomplete
            name="name"
            options={policyHolderOptions}
            textFieldProps={{
              label: formatMessage({
                defaultMessage: "Policy Holder"
              })
            }}
            defaultValue={policyHolderOptions[0].value}
          />
          <BasicCurrencyFormInput
            name="grossValue"
            label={formatMessage({
              defaultMessage: "Approximate Value ($USD)"
            })}
            InputProps={{
              startAdornment: `$`
            }}
          />
        </Stack>
        {showIsFunded && <FundedToTrust />}
        <SpecificGift
          label={
            <Box sx={{ mb: 2 }}>
              <Typography variant="body2" color="grey.700">
                <FormattedMessage defaultMessage="Add Notes On Beneficiary Designations for this Asset" />
              </Typography>
              <Typography variant="caption" color="grey.700">
                <FormattedMessage defaultMessage="IMPORTANT. Any beneficiary designations you add here for this account are for reference purposes only. You must update the beneficiary designations with your financial institution for those changes to be effective." />
              </Typography>
            </Box>
          }
        />
      </FormControl>
    </Stack>
  );
};

export default LifeInsurancePolicy;
